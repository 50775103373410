<template>
  <div class="sconClauInfo">
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <div style="display: flex">
          <div style="width: 120px; textalign: right; paddingright: 12px">
            <div>验货标准</div>
            <el-link :disabled="modcDisableForm.modr_standard" class="vg_pointer" type="primary" @click="clickDialog(1)">选择</el-link>
          </div>
          <el-input v-model="modrForm.modr_standard" :disabled="modcDisableForm.modr_standard" placeholder="暂无验货标准" type="textarea"></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <div style="display: flex">
          <div style="width: 120px; textalign: right; paddingright: 12px">
            <div>条款内容</div>
            <el-link :disabled="modcDisableForm.clau_content" class="vg_pointer" type="primary" @click="clickDialog(2)">选择</el-link>
          </div>
          <el-input
            v-model="modrForm.clau_content"
            :disabled="modcDisableForm.clau_content"
            maxlength="5000"
            placeholder="暂无条款内容"
            show-word-limit
            type="textarea"
          ></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <el-form-item label="送货地址" :prop="'modr_address'">
          <el-input
            v-model="modrForm.modr_address"
            :disabled="modcDisableForm.modr_address"
            :rows="3"
            placeholder="请填写送货地址"
            show-word-limit
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <el-form-item label="备注" prop="modr_remark">
          <el-input
            v-model="modrForm.modr_remark"
            :disabled="modcDisableForm.modr_remark"
            clearable
            placeholder="暂无备注"
            show-word-limit
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-dialog :title="title" :visible.sync="dialogClauVisible" width="70%">
      <ImportClau @importClauChange="importClauChange" />
    </el-dialog>
  </div>
</template>

<script>
import ImportClau from './ImportClau.vue';

export default {
  name: 'SconCaluInfo',
  components: {
    ImportClau
  },
  props: {
    modrForm: Object,
    modcDisableForm: Object,
    isShow: Boolean
  },
  data() {
    return {
      dialogClauVisible: false,
      title: ''
    };
  },
  methods: {
    importClauChange(row) {
      this.dialogClauVisible = false;
      this.$emit('sconClauChange', { type: this.title, value: row });
    },
    clickDialog(value) {
      switch (value) {
        case 1:
          this.title = '验货标准';
          break;
        case 2:
          this.title = '条款内容';
          break;
        default:
          break;
      }
      this.dialogClauVisible = true;
    }
  }
};
</script>

<style></style>
