import { keepNumber } from '@assets/js/regExUtil';

export const modcSubTableProperties = [
  {
    label: '库存类型',
    prop: 'inventory_type',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      disabled: false,
      required: true,
      type: 'newSelect',
      options: { 0: { value: 0, label: '我司库存' }, 1: { value: 1, label: '客户库存' } }
    }
  },
  {
    label: '是否本次合同结算',
    prop: 'settlement',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      disabled: false,
      required: true,
      type: 'newSelect',
      options: { 0: { value: 0, label: '是' }, 1: { value: 1, label: '否' } }
    }
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true
  },

  { label: '中文品名', prop: 'mtrb_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '库存数量',
    prop: 'mtrb_num',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      disabled: false,
      required: true,
      type: 'input',
      input: (val, row) => (row.mtrb_num = keepNumber(val))
    }
  },
  {
    label: '库存存储地',
    prop: 'supp_id',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: true, disabled: false, type: 'newSelect', options: {} }
  }
];
