<template>
  <div class="modcProdList">
    <el-table :data="modcForm.modc_fees_list" border @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary>
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="费用名">
        <template slot="header">
          <span style="color: red">费用名</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_fees_list.' + scope.$index + '.modc_fees_name'">
            <el-input
              v-model="scope.row.modc_fees_name"
              :disabled="scope.row.isNew ? false : modc_fees_list_disable_form.modc_fees_name"
              maxlength="30"
              placeholder="暂无费用名"
              show-word-limit
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="金额(元)">
        <template slot="header">
          <span style="color: red">金额(元)</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_fees_list.' + scope.$index + '.modc_fees_usd'">
            <el-input
              v-model="scope.row.modc_fees_usd"
              :disabled="scope.row.isNew ? false : modc_fees_list_disable_form.modc_fees_usd"
              @blur="scope.row.modc_fees_usd = helper.calcPrice(scope.row.modc_fees_usd, 4, 100000000)"
              @input="scope.row.modc_fees_usd = helper.keepTNum2(scope.row.modc_fees_usd)"
              maxlength="13"
              show-word-limit
              placeholder="暂无金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="备注">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_fees_list.' + scope.$index + '.modc_fees_remark'">
            <el-input
              v-model="scope.row.modc_fees_remark"
              :disabled="scope.row.isNew ? false : modc_fees_list_disable_form.modc_fees_remark"
              maxlength="30"
              placeholder="暂无备注"
              show-word-limit
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';

export default {
  name: 'PartList',
  props: {
    modcForm: {
      type: Object,
      required: true
    },
    modc_fees_list_disable_form: {
      type: Object,
      required: true
    }
  },
  components: {},
  data() {
    return {
      selectionsList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    getSummaries(param) {
      const { columns, data } = param;
      let totalList = ['合计', '', '', '', ''];
      columns.forEach((column, index) => {
        let datas = data;
        let totalFive = 0;
        for (let i in datas) {
          totalFive = totalFive + Number(datas[i].modc_fees_usd) * 10000;
        }
        totalFive = parseInt(totalFive) / 10000;
        totalList[3] = new BigNumber(totalFive).toFixed(4);
      });
      this.$emit('totalExpenses', totalList[3]);
      return totalList;
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
      this.$emit('feesSelect', this.selectionsList);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .showRow {
  display: none;
}
</style>
