<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div v-sticky class="vd_edhea vg_button_group" style="padding-top: 16px; background: white">
      <editHeader :isShow="isShow" :btn="btn" :strForm="modcForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('modcForm')" />
      <div v-if="isShow" class="vd_export vg_mt_16">
        <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
        <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintPart">打印材料采购变更单</el-button>
        <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportPart">下载材料采购变更单PDF</el-button>
      </div>
      <div class="vd_export2 vg_mr_8 vg_mt_16">
        <span style="margin-right: 20px; color: red; font-size: 20px">第{{ modcForm.modc_num }}次修改</span>
        <el-button type="text" @click="recordDialogVisible = true">变更记录</el-button>
        <el-dialog :visible.sync="recordDialogVisible" append-to-body title="变更记录" width="70%" @opened="getRecordData">
          <el-collapse accordion>
            <el-collapse-item :title="item.modc_record" :name="item.modc_record" v-for="(item, index) in recordList" :key="`item${index}`">
              <el-collapse>
                <el-collapse-item title="主内容">
                  <span
                    v-for="(modc, modcIndex) in item.modcHostRecordList"
                    :key="`modc${modcIndex}`"
                    v-html="modc.modc_host_record.replace('现改为', '<span style=\'color:red\'>现改为</span>') + '<br/>'"
                  >
                  </span>
                </el-collapse-item>
                <el-collapse-item title="材料明细">
                  <div v-for="(modcPart, modcPartIndex) in item.modcHostRecordPartList" :key="`modcPart${modcPartIndex}`">
                    <span
                      v-for="(submodcPart, submodcPartIndex) in modcPart"
                      :key="`submodcPart${submodcPartIndex}`"
                      v-html="submodcPart.modc_host_record.replace('现改为', '<span style=\'color:red\'>现改为</span>') + '<br/>'"
                    ></span>
                    <br />
                  </div>
                </el-collapse-item>
                <el-collapse-item title="费用明细">
                  <div v-for="(modcFees, modcFeesIndex) in item.modcHostRecordFeesList" :key="`modcFees${modcFeesIndex}`">
                    <span
                      v-for="(submodcFees, submodcFeesIndex) in modcFees"
                      :key="`submodcFees${submodcFeesIndex}`"
                      v-html="submodcFees.modc_host_record.replace('现改为', '<span style=\'color:red\'>现改为</span>') + '<br/>'"
                    >
                    </span>
                    <br />
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
          </el-collapse>
        </el-dialog>
        <span style="margin-right: 2px">变更单据号:</span>
        <el-input size="mini" style="width: 150px" v-model="modcForm.modc_no" disabled show-word-limit> </el-input>
      </div>
    </div>
    <el-form ref="modcForm" :model="modcForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="变更类型:" prop="modc_type">
              <el-select ref="typeSelect" v-model="modcForm.modc_type" filterable placeholder="请选择变更类型" size="small" @change="typeChange">
                <el-option v-for="item in changeTypeList" :key="item.id" :disabled="item.id === 6" :label="item.label" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="原合同编号" prop="modr_no">
              <el-input v-model="modcForm.modr_no" :disabled="modcDisableForm.modr_no" maxlength="20" placeholder="请填写原合同编号" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="原供应商简称" prop="supp_abbr">
              <el-input v-model="modcForm.supp_abbr" :disabled="modcDisableForm.supp_abbr" maxlength="20" placeholder="请填写原供应商简称" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="采购经办人" prop="modr_stff_id">
              <el-select
                v-model="modcForm.modr_stff_id"
                :disabled="modcDisableForm.modr_stff_id"
                clearable
                filterable
                placeholder="请选择采购经办人"
                size="small"
              >
                <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="外销经办人" prop="cust_stff_name">
              <el-input v-model="modcForm.cust_stff_name" disabled maxlength="20" placeholder="请填写" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :md="8">
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="外销合同号:" prop="scon_no">
              <el-input v-model="modcForm.scon_no" :disabled="modcDisableForm.scon_no" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="新合同编号" prop="new_modr_no">
              <el-input v-model="modcForm.new_modr_no" :disabled="modcDisableForm.new_modr_no" maxlength="20" placeholder="请填写新合同编号" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item :rules="[{ required: [3, 4].includes(modcForm.modc_type), message: ' ' }]" label="新供应商简称" prop="new_supp_id">
              <el-select v-model="modcForm.new_supp_id" :disabled="modcDisableForm.new_supp_id" filterable placeholder="请选择供应商简称">
                <el-option v-for="item in suppList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="采购所属部门" prop="modr_dept_id">
              <el-select v-model="modcForm.modr_dept_id" :disabled="modcDisableForm.modr_dept_id" filterable placeholder="请选择采购所属部门" size="small">
                <el-option v-for="item in deptList" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24">
            <el-form-item label="公司抬头" prop="cptt_id">
              <el-select v-model="modcForm.cptt_id" :disabled="modcForm.modc_type === 1" filterable placeholder="请选择公司抬头" size="small">
                <el-option v-for="item in cpttList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :md="8">
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="备注(内部流转)" prop="modr_remark">
              <el-input v-model="modcForm.modr_remark" :rows="5" maxlength="500" placeholder="请填写" show-word-limit type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="结算方式">
              <el-select v-model="modcForm.modr_payway" filterable placeholder="请选择">
                <el-option
                  v-for="item in [
                    { id: 1, label: '我司结算' },
                    { id: 2, label: '工厂结算' }
                  ]"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row>
        <el-col :md="24" class="vg_mb_16">
          <el-button v-show="modcForm.modc_type === 6" class="vg_mb_8" size="small" type="warning" @click="convertToInventory()">转为库存</el-button>
          <MtrbDetail
            ref="MtrbDetail"
            :modcForm="modcForm"
            :modc_mtrb_list_disable_form="modc_mtrb_list_disable_form"
            @SelectChnage="SelectChnage"
          ></MtrbDetail>
        </el-col>
        <el-col v-show="modcForm.modc_type === 6" :md="24" class="vg_mb_8">
          <el-button class="vg_mb_8" size="small" type="danger" @click="delRow()">删除</el-button>
          <DynamicUTable
            ref="dynamicUTable"
            :tableData="subTableData"
            :columns="modcSubTableProperties"
            :need-pagination="false"
            :is-show="isShow"
            @selection-change="val => (subSelections = val)"
          >
          </DynamicUTable>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="24">
          <el-form-item label="变更内容(给工厂)" prop="modc_content">
            <el-input v-model="modcForm.modc_content" :rows="5" maxlength="500" placeholder="请填写变更内容(给工厂)" show-word-limit type="textarea">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row>
        <el-tabs v-model="activeName" :disabled="true" class="vd_eltab vd_buttom">
          <el-tab-pane :key="'first'" label="费用明细" name="first">
            <div class="vd_but vg_mb_5">
              <el-button v-if="modcForm.modc_type === 2" plain size="small" type="success" @click="feesAdd">新增</el-button>
            </div>
            <CostList :modcForm="modcForm" :modc_fees_list_disable_form="modc_fees_list_disable_form" @feesSelect="feesSelect"></CostList>
          </el-tab-pane>
          <el-tab-pane :key="'second'" label="条款明细" name="second">
            <ModrClauInfo :isShow="isShow" :modcDisableForm="modcDisableForm" :modrForm="modcForm" @sconClauChange="sconClauChange" />
          </el-tab-pane>
        </el-tabs>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="modcForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, getNoCatch, postNoCatch } from '@api/request';
import { modcAPI } from '@api/modules/modc';
import { deptAPI } from '@api/modules/department';
import { stffAPI } from '@/api/modules/staff';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import MtrbDetail from './Component/MtrbDetail.vue';
import { suppAPI } from '@api/modules/supp';
import { modcSubTableProperties } from '@/views/SalesManagement/ModcManage/modc';
import CostList from '@/views/SalesManagement/ModcManage/TabChild/Component/CostList.vue';
import ModrClauInfo from '@/views/SalesManagement/ModcManage/TabChild/Component/ModrClauInfo.vue';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { changeStaffForm, getCptt, getSupp } from '@api/public';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { cloneDeep } from 'lodash';
import { deleteTableRow, getArrayIds } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'modcEditMain',
  components: {
    DynamicUTable,
    ModrClauInfo,
    CostList,
    editHeader,
    inputUser,
    MtrbDetail
  },
  data() {
    return {
      modcSubTableProperties: modcSubTableProperties,
      subTableData: [],
      subSelections: [],
      subTableMap: new Map(),
      suppList: [],
      rules: {
        modc_type: [{ required: true, trigger: 'change', message: ' ' }]
      },
      modcForm: {
        modr_no: null,
        new_modr_no: null,
        new_supp_id: null,
        modc_num: null,
        modr_dept_id: null,
        modr_stff_id: null,
        supp_abbr: null,
        cust_dept_id: null,
        cust_stff_id: null,
        mtrb_name: null,
        modc_item: null,
        prod_part_list: [],
        modc_mtrb_list: [],
        modc_fees_list: [],
        modc_type: null
      },
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: false,
      stffList: [],
      deptList: [],
      dataFlagArrge: [false, false],
      dataFlagList: ['数量', '单价'],
      changeTypeList: [
        { id: 1, label: '取消订单' },
        { id: 2, label: '订单修改' },
        { id: 3, label: '转厂' },
        // { id: 4, label: '部分转厂' },
        { id: 5, label: '采购价修改' },
        { id: 6, label: '转为库存' }
      ], //变更类型
      selectChangeList: [],
      activeName: 'first',
      recordDialogVisible: false,
      recordList: [],
      modcDisableForm: {},
      modc_mtrb_list_disable_form: {},
      modc_fees_list_disable_form: {},
      cpttList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      this.loadingFlag = true;
      await this.getModcInfo();
      await this.getDept();
      await this.getStffUser();
      let [suppObj, suppList] = await getSupp('valueListAndDefault');
      this.suppList = suppList;
      this.modcSubTableProperties.find(({ prop }) => prop === 'supp_id').subItem.options = suppObj;
      await this.initDisableForm();
      this.cpttList = await getCptt();
    },
    initDisableForm() {
      for (let formKey in this.modcForm) {
        this.modcDisableForm[formKey] = true;
      }
      for (let subKey in this.modcForm.modc_mtrb_list.length > 0 ? this.modcForm.modc_mtrb_list[0] : {}) {
        this.modc_mtrb_list_disable_form[subKey] = true;
      }
      for (let subKey in this.modcForm.modc_fees_list.length > 0 ? this.modcForm.modc_fees_list[0] : {}) {
        this.modc_fees_list_disable_form[subKey] = true;
      }
    },
    async changeTypeCommon(newVal) {
      await this.initDisableForm();
      setTimeout(() => {
        switch (newVal) {
          case 1:
            this.modcForm.modc_fees_list.forEach(item => {
              item.modc_fees_usd = null;
            });
            this.modcForm.modc_mtrb_list.forEach(item => {
              item.modc_num = 0;
              item.fact_modc_total = 0;
            });
            for (let modcDisableFormKey in this.modcDisableForm) {
              this.modcDisableForm[modcDisableFormKey] = true;
            }
            for (let subKey in this.modc_mtrb_list_disable_form) {
              this.modc_mtrb_list_disable_form[subKey] = true;
            }
            break;
          case 2:
            this.modcDisableForm.modr_standard = false;
            this.modcDisableForm.clau_content = false;
            this.modcDisableForm.modr_address = false;
            this.modcDisableForm.modr_remark = false;
            this.modc_mtrb_list_disable_form.modc_num = false;
            this.modc_mtrb_list_disable_form.modr_mtrb_pedate = false;
            this.modc_mtrb_list_disable_form.podr_id = false;
            this.modc_fees_list_disable_form.modc_fees_name = false;
            this.modc_fees_list_disable_form.modc_fees_usd = false;
            this.modc_fees_list_disable_form.modc_fees_remark = false;
            break;
          case 3:
            this.modcDisableForm.new_supp_id = false;
            this.modc_mtrb_list_disable_form.modc_num = false;
            this.modc_mtrb_list_disable_form.modc_mtrb_price = false;
            this.modc_mtrb_list_disable_form.modr_mtrb_pedate = false;
            this.modc_mtrb_list_disable_form.podr_id = false;
            break;
          case 5:
            this.modcDisableForm.modr_standard = false;
            this.modcDisableForm.clau_content = false;
            this.modcDisableForm.modr_address = false;
            this.modcDisableForm.modr_remark = false;
            this.modc_mtrb_list_disable_form.modc_num = false;
            this.modc_mtrb_list_disable_form.modr_mtrb_pedate = false;
            this.modc_fees_list_disable_form.modc_fees_name = false;
            this.modc_fees_list_disable_form.modc_fees_usd = false;
            this.modc_fees_list_disable_form.modc_fees_remark = false;
            this.modc_mtrb_list_disable_form.modc_mtrb_price = false;
            this.modc_mtrb_list_disable_form.podr_id = false;
            break;
          case 6:
            this.modcForm.new_supp_id = null;
            break;
        }
        this.modcDisableForm = cloneDeep(this.modcDisableForm);
        this.modc_mtrb_list_disable_form = cloneDeep(this.modc_mtrb_list_disable_form);
        this.modc_fees_list_disable_form = cloneDeep(this.modc_fees_list_disable_form);
      }, 200);
    },
    typeChange(val) {
      this.$confirm('变更类型将会重置数据!', '是否变更类型?', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(async () => {
        this.loadingFlag = true;
        this.getModcInfo(val);
        await this.$refs.typeSelect.blur();
      });
    },
    getRecordData() {
      getNoCatch(modcAPI.getModcRecord, { modc_id: this.modcForm.modc_id }).then(({ data }) => {
        this.recordList = [data.fromList];
      });
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.modcForm.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `库存-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    // 保存
    saveInfo() {
      let modcForm = JSON.parse(JSON.stringify(this.modcForm));
      modcForm.modcInventoryList = this.subTableData.concat(Array.from(this.subTableMap.values()));
      modcForm.modc_mtrb_list.forEach(item => (item.modr_mtrb_pedate ? (item.modr_mtrb_pedate /= 1000) : ''));
      postNoCatch(modcAPI.editModc, modcForm).then(({ data }) => {
        this.$message.success('保存成功!');
        this.isShow = true;
        this.$emit('isShow', this.isShow);
        this.initData();
      });
    },
    // pdf
    clickPrintPart() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.modcForm.modc_id;
          get(modcAPI.exportModcPdfById, { modc_id: str })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }

                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExportPart() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.modcForm.modc_id;
          get(modcAPI.exportModcPdfById, { modc_id: str })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, '材料采购变更单.pdf');
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // 选择子表select
    SelectChnage(val) {
      this.selectChangeList = JSON.parse(JSON.stringify(val));
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取部门
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 指定外销员list
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取表单信息
    getModcInfo(val) {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      getNoCatch(modcAPI.getModcById, { modc_id: props.form_id }).then(({ data }) => {
        this.modcForm = data.form;
        this.btn = data.btn;
        this.subTableData = data.form.modcInventoryList;
        changeStaffForm(this.stffForm, this.modcForm);
        this.calcForm();
        this.calcChangeDataCopy();
        if (val) {
          setTimeout(async () => {
            this.modcForm.modc_type = val;
            await this.changeTypeCommon(val);
          });
        } else {
          setTimeout(async () => {
            await this.changeTypeCommon(this.modcForm.modc_type);
          });
        }
        setTimeout(() => {
          this.loadingFlag = false;
        }, 500);
      });
    },
    // 计算内容
    calcForm() {
      for (let i = 0; i < this.modcForm.modc_mtrb_list.length; i++) {
        if (!this.modcForm.modc_mtrb_list[i].modr_mtrb_pedate) {
          this.modcForm.modc_mtrb_list[i].modr_mtrb_pedate = null;
        } else {
          this.modcForm.modc_mtrb_list[i].modr_mtrb_pedate = this.modcForm.modc_mtrb_list[i].modr_mtrb_pedate * 1000;
        }
        if (this.modcForm.modc_mtrb_list[i].modc_mtrb_price) {
          this.modcForm.modc_mtrb_list[i].modc_mtrb_price = this.helper.haveFour(this.modcForm.modc_mtrb_list[i].modc_mtrb_price);
        }
        if (this.modcForm.modc_mtrb_list[i].prod_mtrb_price) {
          this.modcForm.modc_mtrb_list[i].prod_mtrb_price = this.helper.haveFour(this.modcForm.modc_mtrb_list[i].prod_mtrb_price);
        }
        if (this.modcForm.modc_mtrb_list[i].modc_total || Number(this.modcForm.modc_mtrb_list[i].modc_total) === 0) {
          this.modcForm.modc_mtrb_list[i].modc_total = this.helper.haveFour(this.modcForm.modc_mtrb_list[i].modc_total);
        }
      }
    },
    // 首次加载显示
    calcChangeDataCopy() {
      this.dataFlagArrge = [false, false];
      for (let i = 0; i < this.modcForm.modc_mtrb_list.length; i++) {
        if (this.modcForm.modc_mtrb_list[i].modc_num !== this.modcForm.modc_mtrb_list[i].mtrb_num) {
          this.dataFlagArrge[0] = true;
        }
        if (this.modcForm.modc_mtrb_list[i].prod_mtrb_price !== this.modcForm.modc_mtrb_list[i].modc_mtrb_price) {
          this.dataFlagArrge[1] = true;
        }
      }
    },
    //获取供应商信息
    getSupp() {
      getNoCatch(suppAPI.getSuppsV1).then(({ data }) => {
        this.suppList = data;
      });
    },
    // 新增费用明细
    feesAdd() {
      let item = {
        modc_fees_name: '',
        modc_fees_usd: '',
        modc_fees_remark: '',
        key: this.feesKey,
        isNew: true
      };
      this.modcForm.modc_fees_list.push(item);
      this.feesKey++;
    },
    // 费用选择
    feesSelect(val) {
      this.feesSelectList = JSON.parse(JSON.stringify(val));
    },
    //导入验货标准货 条款内容
    sconClauChange({ type, value }) {
      if (type === '验货标准') {
        this.modcForm.modr_standard = value.clau_content;
      } else if (type === '条款内容') {
        this.modcForm.clau_content = value.clau_content;
      }
    },
    convertToInventory() {
      if (this.selectChangeList.length === 0) return this.$message.warning('请选择一条数据!');
      let ids = getArrayIds(this.subTableData, 'modc_mtrb_id');
      this.selectChangeList.forEach(item => {
        let originalObject = cloneDeep(this.$refs.dynamicUTable.originalObject);
        originalObject.prod_no = item.prod_no;
        originalObject.prod_cust_no = item.cust_catalog_number;
        originalObject.mtrb_name = item.mtrb_name;
        originalObject.mtrb_spec = item.mtrb_spec;
        originalObject.modc_mtrb_id = item.modc_mtrb_id;
        this.subTableData.push(originalObject);
      });
      this.$refs.MtrbDetail.$refs.multiTable.clearSelection();
    },
    delRow() {
      let { tableData, map } = deleteTableRow('modc_inventory_id', this.subTableData, this.subTableMap, this.subSelections);
      this.subTableData = tableData;
      this.subTableMap = map;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}
</style>
