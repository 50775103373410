import { render, staticRenderFns } from "./ModcEditAbout.vue?vue&type=template&id=cc4f764a&scoped=true&"
import script from "./ModcEditAbout.vue?vue&type=script&lang=js&"
export * from "./ModcEditAbout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc4f764a",
  null
  
)

export default component.exports